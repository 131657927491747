<template>
  <div :class="['radiobutton', variant, size]" @keyup.enter="clickOnElement">
    <input
      :id="id"
      ref="radiobutton"
      type="radio"
      :name="name"
      :value="inputValue"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
      @update="onChange"
      @keyup.enter="clickOnElement"
    />
    <label :for="id"><slot /></label>
  </div>
</template>

<script lang="ts" setup>
import { useAttrs, PropType, reactive, ref } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  inputValue: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: false,
    default: '',
  },
  checked: {
    type: Boolean,
    required: false,
  },
  variant: {
    type: String as PropType<'primary' | 'secondary' | 'tertiary' | 'none'>,
    required: false,
    default: 'primary',
  },
})
const emit = defineEmits(['input', 'update:value'])

const attrs = useAttrs()
const radiobutton = ref()
const state = reactive({ content: [] as Array<string> })

function onChange(event: Event): void {
  if ((attrs.value as unknown) instanceof Array) {
    state.content = attrs.value as unknown as Array<string>
    if ((event.target as HTMLInputElement).checked) {
      state.content.push((event.target as HTMLInputElement).value)
    } else {
      state.content = state.content.filter(
        (item) => item !== (event.target as HTMLInputElement).value
      )
    }
    emit('input', state.content)
  } else {
    emit('update:value', (event.target as HTMLInputElement).value)
  }
}

function clickOnElement(event: Event): void {
  radiobutton.value.click()
}
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.radiobutton {
  @include hellix-regular;
  display: flex;
  align-items: center;

  label {
    margin-left: var(--size-half);
    cursor: pointer;
  }

  input[disabled] {
    pointer-events: none;

    & + label {
      pointer-events: none;
    }
  }

  &.secondary {
    [type='radio'] {
      -webkit-appearance: none;
      background-color: var(--color-bilia-white);
      border: 2px solid var(--color-bilia-primary-blue);
      border-radius: 50%;
      box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
      padding: 0.5rem;
      height: 1.2rem;
      width: 1.2rem;
      max-width: 1.2rem;
      max-height: 1.2rem;
      display: inline-block;
      position: relative;
      cursor: pointer;
      margin: 0;
      &:checked:after {
        content: '';
        position: absolute;
        top: 0.15rem;
        left: 0.15rem;
        border-radius: 50%;
        background-color: var(--color-bilia-primary-blue);
        display: block;
        height: 0.7rem;
        width: 0.7rem;
      }
    }
  }

  &.primary {
    [type='radio'] {
      -webkit-appearance: none;
      background-color: var(--color-bilia-white);
      border: 2px solid var(--color-bilia-primary-blue);
      border-radius: 50%;
      padding: 0.5rem;
      height: 1.2rem;
      width: 1.2rem;
      max-width: 1.2rem;
      max-height: 1.2rem;
      display: inline-block;
      position: relative;
      cursor: pointer;
      margin: 0;
      &:checked:after {
        content: '';
        border: 1px solid var(--color-bilia-primary-blue);
        position: absolute;
        top: -1px;
        left: -1px;
        border-radius: 50%;
        background-color: var(--color-bilia-primary-blue);
        display: block;
        height: 100%;
        width: 100%;
        @include check-white();
      }
      @include non-touch-device {
        &:hover:not(:active) {
          filter: brightness(0.85);
        }
      }
    }
  }
}

.large {
  [type='checkbox'] {
    padding: 1rem;
    &:checked:after {
      top: 0.5rem;
      left: 0.5rem;
      height: 1rem;
      width: 1rem;
    }
  }
  label {
    margin-left: var(--size-full);
  }
}

.none {
  label {
    margin: 0;
  }
}

.extended {
  padding-left: var(--size-half);
  border-bottom: solid 1px var(--color-bilia-sand-50);
  input {
    box-shadow: none;
    width: 1.25rem;
  }
  label {
    background: var(--color-bilia-white);
    padding: 0.75rem var(--size-half);
    margin-left: var(--size-half);
    width: 100%;
  }
}
</style>
