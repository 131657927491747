import { GRAPHQL, HTTP } from './http.client'
import userMonitoring from '@/mixins/userMonitoring'
import {
  SubmittedPreliminaryBookingResponseType,
  AddMoreSaleQueryInput,
  BookingResponse,
  StatusCodeResponse,
  SubmitBookingQueryInput,
} from '@/types/generated-types'
import PreliminaryBooking from '@/api/queries/PreliminaryBooking.graphql'
import SubmitBooking from '@/api/queries/SubmitBooking.graphql'
import AddMoreSale from '@/api/queries/AddMoreSaleQuery.graphql'
import { ApolloError } from '@apollo/client'

export const submitBookingRequest = (
  submitBookingQuery: SubmitBookingQueryInput
): Promise<BookingResponse> => {
  return GRAPHQL.query<any>({
    query: SubmitBooking,
    variables: { submitBookingQuery: { ...submitBookingQuery } },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql(
          'SubmitBooking',
          JSON.stringify(response.errors),
          JSON.stringify(submitBookingQuery)
        )
      }
      return response.data.submitBooking as BookingResponse
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('SubmitBooking', error)
      return {} as BookingResponse
    })
}

export const addMoreSalesToBookingRequest = (
  addMoreSaleRequest: AddMoreSaleQueryInput
): Promise<StatusCodeResponse> => {
  return GRAPHQL.query<any>({
    query: AddMoreSale,
    variables: { addMoreSaleQuery: { ...addMoreSaleRequest } },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql(
          'AddMoreSale',
          JSON.stringify(response.errors),
          JSON.stringify(addMoreSaleRequest)
        )
      }
      return response.data as StatusCodeResponse
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('AddMoreSale', error, JSON.stringify(addMoreSaleRequest))
      return {} as StatusCodeResponse
    })
}

export const createPreliminaryBookingByGraphQL = (submitPreliminaryBooking: {
  SiteId: string
  ServiceId: string
  ContainerId: number
  Date: string
  RegionCode: number
  IsTireHotel: boolean
}): Promise<SubmittedPreliminaryBookingResponseType> => {
  const queryVariables = {
    siteId: submitPreliminaryBooking.SiteId,
    serviceId: submitPreliminaryBooking.ServiceId,
    containerId: submitPreliminaryBooking.ContainerId,
    date: submitPreliminaryBooking.Date,
    regionCode: submitPreliminaryBooking.RegionCode,
    isTireHotel: submitPreliminaryBooking.IsTireHotel,
  }
  return GRAPHQL.query<any>({
    query: PreliminaryBooking,
    variables: queryVariables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql(
          'PreliminaryBooking',
          JSON.stringify(response.errors),
          JSON.stringify(queryVariables)
        )
      }
      return response.data.submitPreliminaryBooking as SubmittedPreliminaryBookingResponseType
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql(
        'PreliminaryBooking',
        error,
        JSON.stringify(queryVariables)
      )
      return {} as SubmittedPreliminaryBookingResponseType
    })
}
