<template>
  <teleport to="body">
    <div class="tree-view-wrapper">
      <TreeView :default-open="true" :object="stores" />
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { useBooking } from '@/store/booking.store'
import { useCalendar } from '@/store/calendar.store'
import { usePreliminaryBooking } from '@/store/preliminary-booking.store'
import { useVehicle } from '@/store/vehicle.store'
import { useService } from '@/store/service.store'
import { useSettings } from '@/store/settings.store'
import { useText } from '@/store/text.store'
import { useBranches } from '@/store/branch.store'
import { useConfigCat } from '@/store/config-cat.store'
import TreeView from '@/components/debugComps/TreeView.vue'
import { useRimRenovation } from '@/store/rim-renovation.store'
import { useMoreSale } from '@/store/moresale.store'
import { useSubscriptionBooster } from '@/store/subscription-booster.store'

const cache = new Map()

const settingStore = useSettings()
const configCatStore = useConfigCat()
const textStore = useText()
const vehicleStore = useVehicle()
const serviceStore = useService()
const branchStore = useBranches()
const calendarStore = useCalendar()
const preliminaryBookingStore = usePreliminaryBooking()
const moreSaleStore = useMoreSale()
const bookingStore = useBooking()
const rimRenStore = useRimRenovation()
const subscriptionBoosterStore = useSubscriptionBooster()

const stores = {
  settingStore: settingStore.$state,
  configCatStore: configCatStore,
  textStore: textStore,
  vehicleStore: vehicleStore,
  serviceStore: serviceStore,
  branchStore: branchStore,
  calendarStore: calendarStore,
  preliminaryBookingStore: preliminaryBookingStore,
  moreSaleStore: moreSaleStore,
  bookingStore: bookingStore,
  rimRenovationStore: rimRenStore,
  subscriptionBoosterStore: subscriptionBoosterStore
}
</script>

<style lang="scss" scoped>
.tree-view-wrapper {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0.5rem;
  max-width: 30rem;
  max-height: calc(100vh - 2rem);
  overflow-y: scroll;
  z-index: 1000;
  opacity: 0.5;
}

.tree-view-wrapper:hover {
  opacity: 1;
}
</style>
