<template>
  <Modal v-if="state.showModal" :show="state.showModal" :show-close-btn="false" type="full">
    <template #body>
      <div class="booking-ok">
        <h2 class="h2-size">{{ textResource('generalText', 'bookingServiceLoadingText') }}</h2>
        <div v-if="!state.bookingIsDone && !state.bookingFail" class="is-loading"></div>
        <IconCheckmark :is-valid="state.bookingIsDone" :is-invalid="state.bookingFail" />
        <FadeTransition>
          <div v-if="state.customerWaitText && !state.bookingFail && !state.bookingIsDone">
            <span>{{ state.customerWaitText }}</span>
          </div>
        </FadeTransition>
        <div v-if="state.bookingFail">
          {{ bookingStore.responseMessage }}
          <span>{{ errorText }}</span>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { onUnmounted, reactive, defineAsyncComponent, watch, onMounted } from 'vue'
import { EventBus } from '@/event-bus'
import { useRouter } from '@/router'
import { useBooking } from '@/store/booking.store'
import textResource from '@/mixins/sharedFunctions/textResource'
import IconCheckmark from '@/components/generic/IconCheckmark.vue'
import FadeTransition from '../generic/FadeTransition.vue'

const Modal = defineAsyncComponent(() => import('@/components/generic/Modal.vue'))

const errorText = import.meta.env.VITE_ErrorPage_Heading

const bookingStore = useBooking()

const state = reactive({
  bookingErrorRetries: 0 as number,
  bookingErrorMax: 0 as number,
  secAfterCompleted: 3 as number,
  bookingIsDone: false,
  bookingFail: false,
  showModal: false,
  customerWaitText: '',
})

function onStartCreateBooking(): void {
  state.bookingFail = false
  state.showModal = true
}

function onBookingIsCreated(): void {
  state.bookingIsDone = true
  setTimeout(() => {
    state.showModal = false
  }, state.secAfterCompleted * 1000)
}

function onBookingIsFailed(): void {
  if (state.bookingErrorRetries >= state.bookingErrorMax) {
    state.bookingFail = true
    setTimeout(() => {
      state.showModal = false
      state.bookingErrorRetries = 0
      useRouter().push('error')
    }, 3000)
  } else {
    state.bookingErrorRetries++
  }
}

EventBus.on('start-create-booking', () => {
  onStartCreateBooking()
})
EventBus.on('booking-is-created', () => {
  onBookingIsCreated()
})
EventBus.on('booking-is-failed', () => {
  onBookingIsFailed()
})

onUnmounted(() => {
  EventBus.off('start-create-booking', () => {
    onStartCreateBooking()
  })
  EventBus.off('booking-is-created', () => {
    onBookingIsCreated()
  })
  EventBus.off('booking-is-failed', () => {
    onBookingIsFailed()
  })
})

let firstTimeout: ReturnType<typeof setTimeout>
let secondTimeout: ReturnType<typeof setTimeout>
let thirdTimeout: ReturnType<typeof setTimeout>

watch(
  () => state.showModal,
  (newShowModal) => {
    if (newShowModal) {
      firstTimeout = setTimeout(() => {
        state.customerWaitText = textResource('customerText', 'firstCustomerBookingFeedback')
      }, 10000)

      secondTimeout = setTimeout(() => {
        state.customerWaitText = textResource('customerText', 'secondCustomerBookingFeedback')
      }, 20000)

      thirdTimeout = setTimeout(() => {
        state.customerWaitText = textResource('customerText', 'thirdCustomerBookingFeedback')
      }, 30000)
    } else {
      clearTimeout(firstTimeout)
      clearTimeout(secondTimeout)
      clearTimeout(thirdTimeout)
    }
  }
)
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.booking-ok {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: var(--size-full);
  height: 100%;
}

.is-loading {
  margin-top: var(--size-full);
  margin-bottom: var(--size-full);
  border: 0.3rem solid rgb(233, 233, 233);
  border-top-color: var(--color-bilia-primary-blue);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  animation: spin 1.5s linear infinite;
}

:deep().valid-check {
  margin-top: var(--size-full);
  width: 56px !important;
  height: 56px !important;
  display: block;
}
</style>
