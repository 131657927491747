export {}

declare global {
  interface Array<T> {
    includesObj(obj: Object): boolean
  }
}

Array.prototype.includesObj = function (obj: object) {
  for (let i = 0; i < this.length; i++) {
    if (
      JSON.stringify(this[i], Object.keys(this[i]).sort()) ===
      JSON.stringify(obj, Object.keys(obj).sort())
    )
      return true
  }
  return false
}
