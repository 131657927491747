import { RimTypeOption } from '@/models/RimRenovation/RimRenovation'

export interface countryBasedRimTypes {
  se: RimTypeOption[]
  no: RimTypeOption[]
}

export const rimTypes = {
  se: [
    {
      value: 'lackad',
      name: 'Lackad fälg',
      description: 'Fälgar med lack och finish i samma färg.',
      images: [
        '/assets/enfargad_falg_36-DSC_6066.jpg',
        '/assets/enfargad_falg_42-DSC_6085.jpg',
        '/assets/enfargad_falg_46-DSC_6105.jpg',
      ],
    },
    {
      value: 'svarvad',
      name: 'Diamond Cut (svarvad fälg)',
      description:
        'Kännetecknas av att delar av den lackade ytan svarvats bort som exponerat metallen. Detta skapar ofta ett tvåfärgat utseende.',
      images: [
        '/assets/svarvad_falg_5-DSC_8413.jpg',
        '/assets/svarvad_falg_37-DSC_6070.jpg',
        '/assets/svarvad_falg_51-DSC_6127.jpg',
      ],
    },
  ],
  no: [
    {
      value: 'lackad',
      name: 'Lakkert (ensfarget) felg',
      description: 'Felger med lakk og finish i samme farge.',
      images: [
        '/assets/enfargad_falg_36-DSC_6066.jpg',
        '/assets/enfargad_falg_42-DSC_6085.jpg',
        '/assets/enfargad_falg_46-DSC_6105.jpg',
        '/assets/enfargad_falg_ex1.jpg',
      ],
    },
    {
      value: 'svarvad',
      name: 'Maskinert (diamond cut) felg',
      description:
        'Kjennetegnes ved at deler av felgen har et polert utseende, som oftest på fronten av eikene. Felgen fremstår som tofarget.',
      images: [
        '/assets/svarvad_falg_5-DSC_8413.jpg',
        '/assets/svarvad_falg_37-DSC_6070.jpg',
        '/assets/svarvad_falg_51-DSC_6127.jpg',
      ],
    },
  ],
} as countryBasedRimTypes
