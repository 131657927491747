<template>
  <Modal
    v-if="state.showModal"
    :show="state.showModal"
    position="bottom"
    type="dialog-in-large"
    @close="onModalClose"
  >
    <template #body>
      <div v-if="!state.timeEnded" id="two-minutes-left-modal">
        <h2 class="h2">
          {{ textResource('timeAndPlaceText', 'bookingReservedTwoMinutesHeader') }}
        </h2>
        <p>
          {{ textResource('timeAndPlaceText', 'bookingReservedTwoMinutesInformation') }}
        </p>
        <div class="center">
          <Button variant="primary" size="wide" @onClick="onModalClose">
            {{ textResource('generalText', 'ok') }}
          </Button>
        </div>
      </div>
      <div v-if="state.timeEnded" id="time-ended-modal">
        <h2 class="h2">
          {{ textResource('timeAndPlaceText', 'bookingExpiredHeader') }}
        </h2>
        <p>{{ textResource('timeAndPlaceText', 'bookingExpiredInformation') }}</p>
        <div class="center">
          <Button variant="primary" size="wide" @onClick="onModalClose">
            {{ textResource('timeAndPlaceText', 'newTime') }}
          </Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { EventBus } from '@/event-bus'
import { reactive, onUnmounted, defineAsyncComponent } from 'vue'
import textResource from '@/mixins/sharedFunctions/textResource'
import { useSettings } from '@/store/settings.store'
import { usePreliminaryBooking } from '@/store/preliminary-booking.store'
import Button from '@/components/generic/Button.vue'
import { useService } from '@/store/service.store'

const Modal = defineAsyncComponent(() => import('@/components/generic/Modal.vue'))

const settingsStore = useSettings()
const preliminaryBookingStore = usePreliminaryBooking()
const serviceStore = useService()
const state = reactive({
  showModal: false,
  timeEnded: false,
  timerTwoMinutesLeft: 0 as number,
  timerBookingReservationEnded: 0 as number,
})

function reset(): void {
  state.showModal = false
  state.timeEnded = false
}

function viewWarrningClear(): void {
  clearTimeout(state.timerTwoMinutesLeft)
  state.timerTwoMinutesLeft = window.setTimeout(() => {
    state.showModal = true
  }, settingsStore.warningTwoMinutesLeft((preliminaryBookingStore.reservedTime ?? 2) - 2))
}

function viewBookingReservationClear(): void {
  clearTimeout(state.timerBookingReservationEnded)
  state.timerBookingReservationEnded = window.setTimeout(() => {
    state.showModal = true
    state.timeEnded = true
  }, settingsStore.maxBookingTime(preliminaryBookingStore.reservedTime))
}

function onReservationIsCreated(): void {
  reset()
  viewWarrningClear()
  viewBookingReservationClear()
}

function clearTimers(): void {
  clearTimeout(state.timerTwoMinutesLeft)
  clearTimeout(state.timerBookingReservationEnded)
}

EventBus.on('reservation-is-created', () => {
  if (preliminaryBookingStore.reservedTime) {
    onReservationIsCreated()
  }
})
EventBus.on('booking-is-created', () => {
  clearTimers()
})
EventBus.on('booking-is-failed', () => {
  clearTimers()
})

function onModalClose(): void {
  state.showModal = false
  if (state.timeEnded) {
    preliminaryBookingStore.clearPreliminaryBooking()
    /* branchStore.setup() */
    serviceStore.goToBranchStep()
  }

  onUnmounted(() => {
    EventBus.off('reservation-is-created', () => {
      if (preliminaryBookingStore.reservedTime) {
        onReservationIsCreated()
      }
    })
    EventBus.off('booking-is-failed', () => {
      clearTimers()
    })
    EventBus.off('booking-is-failed', () => {
      clearTimers()
    })
  })
}
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
