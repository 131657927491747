<template>
  <button v-if="!defaultOpen" class="open-button" @click="state.showTree = !state.showTree">
    <b v-if="!state.showTree">˅</b>
    <b v-else>⌃</b>
  </button>
  <ul v-if="state.showTree" class="tree-list">
    <template v-for="(value, key) in object" :key="`${key}-tree-view`">
      <li v-if="!isFunction(value)">
        <b>{{ key }}</b> :
        <template v-if="!checkIfObject(value)"
          >{{ value }} <span class="type-indicator">({{ typeof value }})</span></template
        >
        <template v-else>
          <TreeView :object="value" />
        </template>
      </li>
    </template>
  </ul>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'

const props = defineProps({
  object: {
    type: Object,
    required: false,
    default: {},
  },
  defaultOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const state = reactive({
  showTree: props.defaultOpen,
})
function checkIfObject(object: any) {
  if (typeof object === 'object' && !objectIsEmpty(object)) {
    return true
  }
  return false
}

function isFunction(object: any) {
  return typeof object === 'function'
}
</script>

<style lang="scss" scoped>
.open-button {
  border: none;
  background-color: white;
  &:hover {
    cursor: pointer;
  }
}

.tree-list {
  list-style-type: circle;
}

.type-indicator {
  font-size: 0.8rem;
}
</style>
