<template>
  <div class="is-loading--spin" />
</template>

<style scoped>
/* is-loading */
.is-loading--spin {
  animation: fadein 1s linear;
}

.is-loading--spin:before,
.is-loading--spin-large:before {
  content: '';
  display: block;
  border: 0.3rem solid var(--color-bilia-light-grey);
  border-top-color: var(--color-bilia-black);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.is-loading--spin:before {
  width: 3rem;
  height: 3rem;
}
</style>
