<template>
  <transition name="fade" appear>
    <slot></slot>
  </transition>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s ease;
}
.fade-leave-active {
  transition: opacity 0 ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
