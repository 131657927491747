import { useText } from '@/store/text.store'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'

export default function textResource(
  group: string,
  resource: string,
  replaceString = '',
  replaceStringKey = ''
): string {
  const textStore = useText()

  function getText(group: string, resource: string) {
    const textGroup = textStore[group as keyof typeof textStore]

    // Handle textgroup not fetched yet
    if (textGroup != undefined && Object.keys(textGroup).length <= 0) {
      return ''
    }

    const TextResource = textGroup ? textGroup[resource as keyof typeof textGroup] : ''

    if (TextResource) {
      return replaceString != '' && replaceStringKey != ''
        ? replaceKey(TextResource, replaceString, replaceStringKey)
        : TextResource
    } else {
      console.log(
        `${ErrorTypes.textError}: An attempt to access resource textStore.${group}.${resource} was made. However the resource does not exist.`
      )
      userMonitoring().trackError(
        `${ErrorTypes.textError}: An attempt to access resource textStore.${group}.${resource} was made. However the resource does not exist.`
      )
      return ''
    }
  }

  function replaceKey(string: string, replaceString: string, replaceStringKey: string) {
    return string.replace(replaceStringKey, replaceString)
  }

  return textStore.debugText ? `${group}.${resource}` : getText(group, resource)
}
