import axios from 'axios'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'

export const currentSite = (window as any).sites.find(
  (s: { culture: string }) => s.culture === document.documentElement.lang
)

const baseUrl = currentSite?.apiUrl
const graphQLUrl = currentSite?.graphQLUrl
// const baseUrl = String(import.meta.env.VITE_ApiUrl)
// const graphQLUrl = String(import.meta.env.VITE_GraphQLUrl)
const apiVersion = String(import.meta.env.VITE_ApiVersion)

// GraphQL
export const GRAPHQL = new ApolloClient({
  cache: new InMemoryCache(),
  uri: graphQLUrl,
})

// Requests towards new api in OpenShift
export const HTTP = axios.create({
  baseURL: baseUrl,
  headers: {
    'X-Api-Version': apiVersion,
  },
})
