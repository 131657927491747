<template>
  <button
    :aria-disabled="disableButton"
    :class="[
      'button',
      { 'fake-disabled': disableButton },
      { 'full-width': fullwidth == true },
      { 'is-loading': isLoading },
      { valid: isValid },
      { invalid: isInvalid },
      size,
      variant,
    ]"
    :type="type"
    @click.stop="onClick"
  >
    <span v-if="isValid || isInvalid || isLoading" class="feedback">
      <div v-show="isLoading" class="spin" />
      <IconCheckmark
        v-show="!isLoading"
        :is-valid="isValid && !isInvalid"
        :is-invalid="isInvalid"
      />
    </span>
    <slot />
  </button>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import IconCheckmark from '@/components/generic/IconCheckmark.vue'

const props = defineProps({
  variant: {
    type: String as PropType<
      'primary' | 'secondary' | 'ghost' | 'link' | 'tertiary' | 'black' | 'custom'
    >,
    required: false,
    default: 'primary',
  },
  size: {
    type: String as PropType<
      'xlarge' | 'large' | 'xsmall' | 'small' | 'medium' | 'wide' | 'wider' | 'custom'
    >,
    required: false,
    default: '',
  },
  fullwidth: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  isValid: {
    type: Boolean,
    required: false,
    default: false,
  },
  isInvalid: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    required: false,
    default: 'button',
  },
  disableButton: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['onClick'])

function onClick(event: Event): void {
  if (!props.disableButton) {
    emit('onClick', event)
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/scss/base';
.button {
  @include hellix-semibold;
  border: none;
  border-radius: 3px;
  padding: var(--size-half) var(--size-full);
  margin: 0;
  color: var(--btn-color);
  font-size: var(--font-n);
  line-height: var(--font-n);
  white-space: nowrap;
  &.full-width {
    width: 100%;
  }
  &:not(:disabled, .fake-disabled) {
    cursor: pointer;
  }
  &.small {
    padding: var(--size-quarter) var(--size-half);
    font-size: var(--font-s);
    line-height: var(--font-s);
    height: 1.5rem;
  }
  &.xsmall {
    padding: 0 var(--size-quarter);
    font-size: var(--font-xs);
    line-height: var(--font-s);
    height: 1.2rem;
  }
  &.medium {
    padding: var(--size-quarter) var(--size-full);
    font-size: var(--font-s);
    line-height: var(--font-s);
    height: 2rem;
  }
  &.large {
    height: 2.5rem;
    padding: 0.65em 1em;
  }
  &.xlarge {
    height: 3rem;
    padding: 0.9em 2.5em;
  }
  :deep() svg {
    margin-bottom: var(--font-misalign);
  }
}
.primary {
  background: var(--btn-primary-bg-color);
  &:disabled,
  &.fake-disabled {
    background: rgba(209, 64, 59, 0.6);
  }

  &:not(:disabled, .fake-disabled) {
    @include ripple;
    @include non-touch-device {
      &:hover:not(:active) {
        filter: brightness(1.15);
      }
    }
  }
}
.secondary {
  background: var(--color-btn-secondary-bg);
  &:not(:disabled, .fake-disabled) {
    @include ripple;
    @include non-touch-device {
      &:hover:not(:active) {
        filter: brightness(1.15);
      }
    }
  }
}
.ghost {
  border: solid 1px var(--color-bilia-primary-blue);
  color: var(--color-secondary-darker);
  background-color: var(--color-bilia-white);
  &:disabled {
    border: solid 1px var(--color-bilia-sand-100);
    background-color: var(--color-bilia-sand-50);
    color: var(--color-bilia-sand-100);
    :deep(span, b, p) {
      color: var(--color-bilia-sand-100);
    }
  }
  &:not(:disabled, .fake-disabled) {
    @include ripple(var(--color-bilia-black));
    @include non-touch-device {
      &:hover:not(:active) {
        filter: brightness(0.85);
      }
    }
  }
}
.tertiary {
  color: var(--color-bilia-washed-blue);
  background-color: var(--color-super-soft-blue-2);
  border-radius: 4px;
  &:not(:disabled, .fake-disabled) {
    @include ripple(var(--color-bilia-black));
    @include non-touch-device {
      &:hover:not(:active) {
        filter: brightness(1.03);
      }
    }
  }

  :deep(span) {
    color: var(--color-bilia-washed-blue);

    svg path {
      fill: var(--color-bilia-washed-blue);
    }
  }
}

.black {
  background: var(--color-bilia-primary-blue);
  color: var(--color-bilia-white);
  &:disabled,
  &.fake-disabled {
    background: rgba(209, 64, 59, 0.6);
  }

  &:not(:disabled, .fake-disabled) {
    @include ripple;
    @include non-touch-device {
      &:hover:not(:active) {
        filter: brightness(1.15);
      }
    }
  }
}

.wide {
  min-width: 8rem;
}
.wider {
  min-width: 12rem;
}
.custom {
  color: var(--color-bilia-black);
  padding: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
}
.icon-link,
.link {
  color: var(--color-bilia-mid-blue);
  padding: 0;
  display: inline-flex;
  align-items: center;
  background: none;
  border-radius: 0;
  cursor: pointer;
}
.link {
  //		@include underline;
  &:disabled {
    color: var(--text-disabled);
    //			@include underline-disable;
    :deep(.icon svg) {
      fill: var(--text-disabled);
    }
  }
  @include non-touch-device {
    &:hover:not(:active) {
      text-decoration: underline;
    }
  }
  &:active {
    filter: brightness(0.85);
  }
}
.icon-link {
  padding: 0 0 1px 0;
  display: flex;
  align-items: center;
  background: none;
  cursor: pointer;
  :deep() .icon {
    margin-right: var(--size-half);
  }
  .text {
    @include underline;
    display: inline;
  }
  &:disabled {
    .text {
      @include underline-disable;
    }
    .icon {
      fill: var(--text-disabled);
    }
  }
}

.selected {
  background-color: var(--color-selected);
  &:not(.ghost) {
    border-color: var(--color-selected);
    color: var(--color-bilia-primary-blue);
  }
}

.chips {
  display: flex;
  align-items: center;
  color: var(--color-bilia-black);
  background: inherit;
  padding: var(--size-half) 0 var(--size-half) 0;
  background: var(--color-super-soft-blue);
  padding: var(--size-half);
  border: solid 1px var(--color-secondary-darker);
  &:after {
    content: '';
    margin-left: 0.5rem;
    background-color: var(--color-secondary-darker);
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    border: solid 3px var(--color-secondary-darker);
    @include icon-close-white();
    background-position: center;
  }
}

.is-loading .spin {
  border: 0.1rem solid var(--color-brand-grey-10p);
  border-top-color: var(--color-bilia-primary-blue);
  border-radius: 50%;
  width: var(--size-full);
  height: var(--size-full);
  animation: spin 1s linear infinite;
}

.feedback {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.valid,
.invalid,
.is-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  > span:first-child {
    line-height: 0;
    margin-right: var(--size-half);
  }
  .spin {
    line-height: 0;
    border: 0.1rem solid var(--color-brand-grey-10p);
    border-top-color: var(--color-bilia-primary-blue);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
}

// .content {
//   padding-bottom: 10rem;
// }

.icon {
  height: 24px;
  width: 24px;

  ::v-deep(svg) {
    height: 24px;
    width: 24px;
  }
}
</style>
