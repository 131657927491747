<template>
  <template v-if="showDebug()">
    <div class="widget-wrapper">
      <div v-show="state.showPopOver" class="popover">
        <div class="popup-wrapper">
          <h1 class="header">Bilia debug tool</h1>
          <a class="grid-right home-link" href="/">
            <Icon name="home" />
          </a>
          <hr class="divider" />
          <span>Version </span>
          <b class="info">{{ version }}</b>
          <span>Language </span>
          <b class="info">{{ culture }}</b>
          <span>Enviornment </span>
          <b class="info">{{ env }}</b>
          <hr class="divider" />
          <h2 class="header">Blazor</h2>
          <button class="about-button" @click="state.showAboutBlazorModal = true">
            <span>?</span>
          </button>
          <button
            id="blazor-show"
            name="blazor-show"
            class="grid-right"
            @click="textStore.debugText = !textStore.debugText"
          >
            {{ textStore.debugText ? 'Show text resource' : 'Show text key' }}
          </button>

          <hr class="divider" />
          <h2 class="full-header">Store</h2>
          <button
            id="blazor-show"
            class="grid-right"
            name="blazor-show"
            @click="state.showStoreTreeView = !state.showStoreTreeView"
          >
            {{ state.showStoreTreeView ? 'Hide' : 'Show' }} store
          </button>
          <StoreTreeView v-if="state.showStoreTreeView" />
        </div>
      </div>
      <div class="widget" @click="state.showPopOver = !state.showPopOver">
        <Icon v-if="!state.showPopOver" class="icon" name="settings_white" />
        <Icon v-else class="icon" name="close_white" />
      </div>
    </div>
    <Modal
      :show="state.showAboutBlazorModal"
      type="dialog-in-large"
      @close="state.showAboutBlazorModal = false"
    >
      <template #header>About Blazor Debug</template>
      <template #body>
        <p>
          The Blazor text finder changes a text resource to its key value. These can then be cross
          refferenced in Blazor to find the corresponding data configuration.
        </p>
      </template>
    </Modal>
  </template>
</template>

<script lang="ts" setup>
import { useText } from '@/store/text.store'
import { reactive, defineAsyncComponent } from 'vue'
import Icon from '@/components/generic/Icon.vue'
import StoreTreeView from '@/components/debugComps/StoreTreeView.vue'

const Modal = defineAsyncComponent(() => import('@/components/generic/Modal.vue'))

const culture = import.meta.env.VITE_Culture
const version = import.meta.env.VITE_RELEASE_VERSION
const env = import.meta.env.MODconst
const textStore = useText()

const state = reactive({
  showPopOver: false,
  showStoreTreeView: false,
  showAboutBlazorModal: false,
})

function showDebug() {
  return (
    (window as any).VITE_Env == 'dev' || (window as any).VITE_Env == 'test' /* ||
        router.currentRoute.value.query.debug == 'true' */
  )
}
</script>

<style lang="scss" scoped>
.widget-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: fixed;
  left: 0;
  bottom: 1rem;
  z-index: 100000;
}

.icon {
  height: 1rem;
}

.widget {
  background-color: #19232c;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0 50% 50% 0;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    background-color: grey;
  }
}

.popover {
  padding: 1rem;
  background-color: white;
  border-top: solid 1px #19232c;
  border-right: solid 1px #19232c;
  border-bottom: solid 1px #19232c;
  border-radius: 0 4px 4px 0;
}

.popup-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 0.5rem;
}

.full-header {
  grid-column: 1 / -1;
}

.home-link {
  display: flex;
  justify-content: flex-end;
}

.about-button {
  border: 1px solid black;
  background-color: transparent;
  color: black;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  justify-self: flex-end;
}

button {
  border: none;
  color: white;
  background-color: #192332;
  border-radius: 4px;
  padding: 0.5rem;
}

.grid-right {
  grid-column: 2;
}

button:hover {
  background-color: grey;
}

.divider {
  grid-column: 1 / -1;
  width: 100%;
}

.info {
  justify-self: flex-end;
}

.tiny-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tiny-button {
  background-color: transparent;
  color: black;
  padding: 0;
  margin: 0;
  border: 1px solid black;
  padding: 0.1rem;
}
</style>
