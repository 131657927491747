import { getSettingsRequest } from '@/api/setting.service'
import { Settings } from '@/models/Application/settings'
import { siteData } from '@/models/Application/siteData'
import { UserLocation } from '@/models/Calendar/userLocation'
import { defineStore } from 'pinia'

interface UseSettingsState {
  culture: string
  userLocation: UserLocation
  userDeniedPosition: boolean
  settings: Settings
  evaluatingRoutes: boolean
  siteData: siteData
}

export const useSettings = defineStore('settings', {
  state: () =>
    ({
      culture: '' as string,
      userLocation: {} as UserLocation,
      userDeniedPosition: false,
      settings: {} as Settings,
      evaluatingRoutes: false,
      siteData: {} as siteData,
    } as UseSettingsState),
  getters: {
    twoLetterLanguage(): string {
      return this.culture.split('-')[1].toLocaleLowerCase()
    },
  },
  actions: {
    maxBookingTime(minutes = 15): number {
      return minutes * 60 * 1000
    },
    warningTwoMinutesLeft(minutes = 13): number {
      return minutes * 60 * 1000
    },

    async fetchSettings() {
      await getSettingsRequest(this.culture).then((settings) => {
        this.settings = settings
      })
    },

    async fetchSiteData() {
      this.siteData = (window as any).sites.find(
        (site: { culture: string }) => site.culture === this.culture
      )
    },
  },
})
