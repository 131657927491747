import mitt from 'mitt'
import { CommonMessage } from './models/Application/CommonMessage'

type Events = {
  'common-message': CommonMessage | undefined
  validateAll: undefined
  'start-create-booking': undefined
  'booking-is-created': undefined
  'booking-is-failed': undefined
  validateById: string | undefined
  'reservation-is-started': undefined
  'reservation-is-created': undefined
  'service-configuration-submit': undefined
}

export const EventBus = mitt<Events>()
