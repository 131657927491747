class imboxchat {
  isLoading = false
  readonly IMBOX_SCRIPT_ID: string = 'imbox-script'
  readonly IMBOX_ID_CONTAINER: string = 'imBoxChat'
  readonly OPEN_FORM_ID: string = 'imbox-openform'

  initChat(imboxId: string) {
    const isImboxScriptAdded = !!document.getElementById(this.IMBOX_SCRIPT_ID)

    if (!isImboxScriptAdded) {
      this.addImboxScriptId(imboxId)
      this.loadImboxScript()
    }
  }

  addImboxScriptId(imboxId: string) {
    if (document.getElementById(this.IMBOX_ID_CONTAINER) === null) {
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.id = this.IMBOX_ID_CONTAINER
      scriptElement.async = true
      scriptElement.defer = true

      const content = `
			var _sid = '${imboxId}';
			var _imboxSettings = { disableHistoryHooks: true};
		`

      try {
        scriptElement.appendChild(document.createTextNode(content))
        document.head.appendChild(scriptElement)
      } catch (e) {
        scriptElement.text = content
        document.head.appendChild(scriptElement)
      }
    }
  }

  loadImboxScript() {
    this.isLoading = true
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = this.IMBOX_SCRIPT_ID
    script.async = true
    script.defer = true
    const protocol: string = document.location.protocol === 'https:' ? 'https://' : 'http://'
    script.src = `${protocol}files.imbox.io/app/dist/initWidget.js`

    document.head.appendChild(script)

    script.onload = () => {
      this.isLoading = false
    }
  }

  setImboxPosition(bottom: string, transitionTime: string) {
    const popupWindow = document.querySelectorAll<HTMLElement>(
      '[id^=frame-container-zoid-imbox-widget]'
    )
    const widget = document.querySelectorAll<HTMLElement>('[name^=__zoid__imbox_launcher__]')

    if (popupWindow) {
      for (const item of popupWindow) {
        item.style.cssText = `bottom: calc(15px + 78px + ${bottom}) !important; transition:bottom ${transitionTime}s linear;`
      }
    }

    if (widget) {
      for (const item of widget) {
        item.style.cssText = `bottom: ${bottom} !important; transition:bottom ${transitionTime}s linear;`
      }
    }
  }
}

export { imboxchat }
