import { GRAPHQL } from './http.client'
import userMonitoring from '@/mixins/userMonitoring'
import CustomerByPhoneNumber from '@/api/queries/CustomerByPhoneNumber.graphql'
import { ApolloError } from '@apollo/client'
import { Customer } from '@/types/generated-types'

export const getDriverByNameRequest = (payload: {
  mobileNumber: string
  uuid: string
  culture: string
  registrationNumber: string
}): Promise<Customer> => {
  const requestParams = {
    uuid: payload.uuid,
    culture: payload.culture,
    mobileNumber: payload.mobileNumber,
    registrationNumber: payload.registrationNumber,
  }
  return GRAPHQL.query<any>({
    query: CustomerByPhoneNumber,
    variables: requestParams,
    errorPolicy: 'all',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql(
          'CustomerByPhoneNumber',
          JSON.stringify(response.errors),
          JSON.stringify(requestParams)
        )
      }
      return response.data.customerByPhoneNumber as Customer
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql(
        'CustomerByPhoneNumber',
        error,
        JSON.stringify(requestParams)
      )
      return {} as Customer
    })
}
