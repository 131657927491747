<template>
  <span v-if="name" ref="iconRef" :key="name" :class="['icon', !autoSize ? 'setSize' : 'autoSize']">
  </span>
</template>

<script lang="ts" setup>
import firstCharToUpper from '@/mixins/sharedFunctions/firstCharToUpper'
import { ref, watch } from 'vue'
import userMonitoring from '@/mixins/userMonitoring'

const cache = new Map()

const props = defineProps({
  name: {
    type: String,
    required: false,
    default: '',
  },
  placeholderText: {
    type: String,
    required: false,
    default: '',
  },
  autoSize: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const iconRef = ref()

async function importIcon(): Promise<any> {
  let url = new URL(`../../assets/svg/${props.name}.svg`, import.meta.url)
  return url
}

async function renderIcon(src: string): Promise<void> {
  if (!cache.has(src)) {
    try {
      cache.set(
        src,
        fetch(src).then((r) => r.text())
      )
    } catch (e) {
      cache.delete(src)
    }
  }
  if (cache.has(src)) {
    let svg = await cache.get(src)
    if (svg !== '' && iconRef.value !== null) {
      iconRef.value.innerHTML = svg
    } else if (iconRef.value !== null) {
      handleMissingSvg(src)
    }
  }
}

function handleMissingSvg(errorContext: any) {
  userMonitoring().trackError(
    `Icon ${props.name}.svg does not exist and was therefore not imported.`,
    'Icon svg error',
    {
      svgDataContext: errorContext,
    }
  )
  iconRef.value.innerHTML = firstCharToUpper(props.placeholderText)
}

if (props.name != '') {
  importIcon().then((res: any) => {
    res.pathname.endsWith('.svg') ? renderIcon(res.href) : handleMissingSvg(res)
  })
}

watch(
  () => props.name,
  () => {
    if (props.name != '') {
      importIcon().then((res: any) => {
        res.pathname.endsWith('.svg') ? renderIcon(res.href) : handleMissingSvg(res)
      })
    }
  }
)
</script>

<style lang="scss" scoped>
.icon {
  display: inline-flex;
  line-height: 0;
}
.setSize {
  :deep() svg {
    width: 1.2em;
    height: 100%;
  }
}

.autoSize {
  width: 100%;
  height: 100%;
}
</style>
