import { Region } from '@/models/Book/Region'
import { HTTP } from './http.client'
import { useSettings } from '@/store/settings.store'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'

export const getRegionsRequest = (culture: string, carMake: string): Promise<Array<Region>> => {
  return HTTP.get<Array<Region>>(`Region/${useSettings().siteData.siteId}/${carMake}`)
    .then((response) => {
      if (response.status == 204) {
        return []
      }

      return response.data
    })
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.api}: Region/${
          useSettings().siteData.siteId
        }/${carMake} failed with error: ${error}`
      )
      return []
    })
}
