<template>
  <div class="usp-list">
    <div class="inner">
      <h2 class="h3">{{ textStore.vehicleText.uspHeading }}</h2>
      <ul class="list">
        <li v-for="(usp, index) in textStore.usps" :key="`usp-${index}`" class="item">
          <Icon name="check" />
          <span data-text="Not a blazor resource">{{ usp.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useText } from '@/store/text.store'
import Icon from '@/components/generic/Icon.vue'

const props = defineProps({
  items: {
    type: Array,
    required: false,
    default: null,
  },
  variant: {
    type: String,
    required: false,
    default: '',
  },
})

const textStore = useText()
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';
// h2{
//   text-align: center;
// }
.usp-list {
  // margin-top: var(--size-double);
  grid-column: full;
  background-color: var(--color-bilia-sand-100);
  display: flex;
  justify-content: center;
  min-height: 10rem;
}
.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: var(--content-grid);
  padding-left: var(--size-full);
  padding-right: var(--size-full);
  padding-top: var(--size-full);
  padding-bottom: var(--size-full);
  @include media-large {
    align-items: center;
  }
}

.list {
  margin-top: var(--size-full);
  display: grid;
  padding-left: 0;
  @include media-large {
    grid-template-columns: 1fr 1fr 1fr;
  }
  row-gap: var(--size-full);
  column-gap: var(--size-full);
  width: 100%;
}
.item {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  column-gap: var(--size-full);
  align-items: center;
}

.icon {
  height: 1.9rem;
  width: 1.9rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  border: solid 2px #000;
  border-radius: 50%;
}
</style>
