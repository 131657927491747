// WILL BE REMOVED - temporarly solution until operationType is not needed and serviceId is used
interface OperationTypeEnum {
  [key: number]: string
}

export const operationTypeEnum: OperationTypeEnum = {
  0: 'Service',
  1: 'Reparation',
  2: 'TireShift',
  3: 'WheelShift',
  4: 'DamageInspection',
  5: 'WindscreenChipRepair',
  6: 'WindscreenReplacement',
  7: 'Inspection',
  8: 'WheelSettings',
  9: 'Rekond',
  10: 'WheelAdjustment',
}
