import { PriceList } from '@/models/RimRenovation/RimRenovation'

export interface countryBasedPriceList {
  se: PriceList
  no: PriceList
}

export const priceList = {
  se: {
    lackad: {
      basrenovering: {},
      helrenovering: {
        17: 2395,
        18: 2695,
        19: 2695,
        20: 2695,
        21: 3095,
        22: 3095,
        23: 3095,
        24: 3095,
      },
    },
    svarvad: {
      basrenovering: {},
      helrenovering: {
        17: 2995,
        18: 3295,
        19: 3295,
        20: 3295,
        21: 3695,
        22: 3695,
        23: 3695,
        24: 3695,
      },
    },
  },
  no: {
    lackad: {
      basrenovering: {},
      helrenovering: {
        17: 2690,
        18: 3190,
        19: 3690,
        20: 3990,
        21: 4190,
        22: 4390,
        23: 4390,
        24: 4390,
      },
    },
    svarvad: {
      basrenovering: {},
      helrenovering: {
        17: 3290,
        18: 3690,
        19: 4290,
        20: 4590,
        21: 4790,
        22: 4990,
        23: 4990,
        24: 4990,
      },
    },
  },
} as countryBasedPriceList
