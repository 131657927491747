import { useConfigCat } from '@/store/config-cat.store'
import { datadogRum } from '@datadog/browser-rum'
import { TrackJS } from 'trackjs'
interface userMonitoring {
  init: () => void
  trackError: (error: string, errorLabel?: string, context?: object | undefined) => void
  trackErrorApi: (endpoint: string, request: any, error: any) => void
  trackErrorGraphql: (query: string, error: any, request?: any) => void
  trackAction: (actionName: string, context?: object | undefined) => void
}

export const enum ErrorTypes {
  apiOld = 'API-Legacy',
  api = 'API',
  graphQL = 'GraphQL',
  google = 'Google',
  faultyData = 'Faulty-Data',
  feState = 'State-Error',
  textError = 'Text-Error',
}

export default (): userMonitoring => {
  const enableDev = true

  function init() {
    if (enableDev || !import.meta.env.DEV) {
      initDataDog()
      initTrackJs()
    }
  }

  function initDataDog() {
    useConfigCat()
      .getWholeNumberSetting('dataDogSessionReplaySampleRate')
      .then((dataDogSessionReplaySampleRate: number) => {
        datadogRum.init({
          applicationId: String(import.meta.env.VITE_Datadog_ApplicationId),
          clientToken: String(import.meta.env.VITE_Datadog_ClientToken),
          site: String(import.meta.env.VITE_Datadog_Site),
          service: String(import.meta.env.VITE_Datadog_Service),
          env: `${(window as any).VITE_Env}`,
          version: String(import.meta.env.VITE_BUILD_ID), //String(import.meta.env.VITE_RELEASE_VERSION).replace(/ /g, '_'),
          sessionSampleRate: 100,
          sessionReplaySampleRate: dataDogSessionReplaySampleRate ?? 0,
          trackInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        })
        datadogRum.startSessionReplayRecording()
      })
  }

  function initTrackJs() {
    TrackJS.install({
      token: String(import.meta.env.VITE_Trackjs_Token),
      version: String(import.meta.env.VITE_RELEASE_VERSION),
      application: String(import.meta.env.VITE_Trackjs_Application),
    })
  }

  function trackError(
    errorString: string,
    errorLabel = '',
    context: object | undefined = undefined
  ) {
    if (enableDev || !import.meta.env.DEV) {
      TrackJS.track(errorString)

      if (errorLabel == '' && context == undefined) {
        datadogRum.addError(new Error(errorString))
      } else {
        datadogRum.addError(errorLabel, {
          errorContext: context,
          featureFlags: useConfigCat().featureFlags,
        })
      }
    }
  }

  function trackErrorGraphql(query: string, error: string, request?: any) {
    trackError(
      `${ErrorTypes.graphQL}: ${query} failed ${
        request !== undefined ? `| REQUEST:${request} ` : ''
      }| ERROR:${JSON.stringify(error)}`,
      `${ErrorTypes.graphQL}: ${query} failed`,
      { request: request ?? undefined, error: error }
    )
  }

  function trackErrorApi(endpoint: string, request: any, error: any) {
    trackError(
      `${ErrorTypes.api}: ${endpoint} failed | REQUEST:${request} | ERROR:${JSON.stringify(error)} `
    )
  }

  function trackAction(actionName: string, context: object | undefined = undefined) {
    try {
      datadogRum.addAction(actionName, {
        featureFlags: useConfigCat().featureFlags,
        booking: context,
      })
    } catch (error: any) {
      trackError(error)
    }
  }

  return {
    init,
    trackError,
    trackErrorApi,
    trackErrorGraphql,
    trackAction,
  }
}
