<template>
  <transition name="fade">
    <div v-if="!objectIsEmpty(theVehicle) && !hideOnPath" class="edit-vehicle">
      <div class="vehicle-info">
        <span class="model">{{ theVehicle.make }} {{ theVehicle.model }}</span>
        <span class="year">{{ theVehicle.year }}</span>
      </div>
      <Button
        id="editRegnr"
        variant="custom"
        class="edit"
        data-dd-action-name="REGNUMBER - Open edit regnumber"
        :aria-label="textResource('generalText', 'changeRegnr')"
        @click="state.showEnterRegnumber = true"
      >
        <Icon
          v-if="textResource('vehicleText', 'regNrSource')"
          class="icon-regnumber"
          :name="textResource('vehicleText', 'regNrSource')"
        />
        <span class="regnumber-text">
          {{ formatRegnumber(theVehicle.registrationNumber) }}
        </span>
        <Icon class="edit-icon" name="edit" />
      </Button>
    </div>
  </transition>
  <Modal
    :show="state.showEnterRegnumber"
    position="bottom"
    type="dialog-in-large"
    :show-close-btn="theVehicleIsDefined"
    :outside-close="theVehicleIsDefined"
    @close="onCloseModal"
  >
    <template #header>
      <span class="h3-size">{{
        textResource('vehicleText', 'enterRegistrationNumberHeading')
      }}</span>
    </template>
    <template #body>
      <EnterRegnumber
        :show-header="false"
        :init-reg-number="theVehicle.registrationNumber"
        @complete="state.showEnterRegnumber = false"
      />
    </template>
  </Modal>
</template>
<script lang="ts" setup>
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import formatRegnumber from '@/mixins/sharedFunctions/formatRegnumber'
import { useRouter } from 'vue-router'
import { computed, reactive, defineAsyncComponent } from 'vue'
import { useBooking } from '@/store/booking.store'
import textResource from '@/mixins/sharedFunctions/textResource'
import Button from '@/components/generic/Button.vue'
import Icon from '@/components/generic/Icon.vue'
import EnterRegnumber from '@/components/serviceandvehicle/EnterRegnumber.vue'
import { useVehicle } from '@/store/vehicle.store'

const Modal = defineAsyncComponent(() => import('@/components/generic/Modal.vue'))

const emit = defineEmits(['vehiclechanged', 'backclicked'])

const router = useRouter()

const bookingStore = useBooking()

const vehicleStore = useVehicle()

const theVehicle = computed(() => {
  return bookingStore.vehicle
})
const theVehicleIsDefined = computed(() => {
  return Object.keys(bookingStore.vehicle).length !== 0
    ? bookingStore.vehicle.registrationNumber !== ''
    : false
})

const hideOnPath = computed(() => {
  //return ['reservation', 'confirmation'].includes(String(router.currentRoute.value.name))
  return ['confirmation', 'vehicle', 'rim-renovation', 'rim-renovation-confirmation'].includes(
    String(router.currentRoute.value.name)
  )
})

const state = reactive({
  showEnterRegnumber: false,
})

function onCloseModal() {
  state.showEnterRegnumber = false
}
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.edit-vehicle {
  display: flex;
}

.vehicle-info {
  display: flex;
  flex-direction: column;
  padding-right: var(--size-half);
  text-align: right;
  justify-content: center;
  overflow: hidden;
}

.model {
  @include hellix-semibold;
  font-size: var(--font-s);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.year {
  font-size: var(--font-xs);
  color: var(--color-bilia-dark-grey);
}
.custom.edit {
  line-height: 0;
  padding: var(--size-quarter) var(--size-half);
  border: solid 1px var(--color-bilia-sand-100);
  display: flex;
  align-items: center;
  @include h4-size;

  &:deep(svg) {
    margin-bottom: 0;
  }
}

.regnumber-text {
  margin-left: var(--size-half);
  margin-right: var(--size-half);
}
.icon-regnumber {
  font-size: 0.6em;
}

.edit-icon {
  font-size: 0.85em;
}

.fade-enter-active {
  transition: opacity 0.5s ease;
}
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
