import { defineStore } from 'pinia'
import {
  BoosterInputField,
  SubscriptionBooster,
  SubscriptionBoosterData,
} from '@/types/generated-types'
import { useBooking } from './booking.store'

export interface SubscriptionBoosterInput extends SubscriptionBooster {
  formData?: BoosterInputFieldExtended[]
}
interface BoosterInputFieldExtended extends BoosterInputField {
  value: string
  valid: boolean
}
interface SubscriptionBoosterState {
  subscriptionBoosters: SubscriptionBooster[]
  selectedSubscriptionBoosters: SubscriptionBoosterInput[]
}

export const useSubscriptionBooster = defineStore('subscriptionBooster', {
  state: () =>
    ({
      subscriptionBoosters: [] as SubscriptionBooster[],
      selectedSubscriptionBoosters: [] as SubscriptionBoosterInput[],
    } as SubscriptionBoosterState),
  getters: {
    isIncludedInCurrentSubscriptionBoosters(): (
      subscriptionBooster: SubscriptionBooster
    ) => boolean {
      return (subscriptionBooster: SubscriptionBooster) => {
        return this.selectedSubscriptionBoosters.some(
          (selectedSubscriptionBooster) => selectedSubscriptionBooster.id === subscriptionBooster.id
        )
      }
    },
  },
  actions: {
    setSubscriptionBoosters(subscriptionBoosters: SubscriptionBooster[]): void {
      this.selectedSubscriptionBoosters = []
      this.subscriptionBoosters = subscriptionBoosters
    },

    addToSelectedSubscriptionBoosters(subscriptionBooster: SubscriptionBoosterInput) {
      const index = this.selectedSubscriptionBoosters.findIndex(
        (booster) => booster.id === subscriptionBooster.id
      )

      if (index !== -1) {
        this.selectedSubscriptionBoosters.splice(index, 1, subscriptionBooster)
      } else {
        this.selectedSubscriptionBoosters = [
          ...this.selectedSubscriptionBoosters,
          subscriptionBooster,
        ]
      }
    },

    removeFromSelectedSubscriptionBoosters(subscriptionBoosterId: string) {
      this.selectedSubscriptionBoosters.splice(
        this.selectedSubscriptionBoosters.findIndex(
          (subscriptionBooster) => subscriptionBooster.id === subscriptionBoosterId
        ),
        1
      )
    },
    toSubscriptionBoosterData(
      subscriptionBoosterInput: SubscriptionBoosterInput
    ): SubscriptionBoosterData {
      return {
        bookingConfirmationText: subscriptionBoosterInput.bookingConfirmationText,
        boosterId: subscriptionBoosterInput.id,
        header: subscriptionBoosterInput.header,
        formData: subscriptionBoosterInput.formData?.map((boosterInputField) => ({
          name: boosterInputField.name,
          value: boosterInputField.value,
        })),
      }
    },
    getSelectedSubscriptionBooster(subscriptionBoosterId: string): SubscriptionBoosterInput {
      return this.selectedSubscriptionBoosters.find(
        (subscriptionBooster) => subscriptionBooster.id === subscriptionBoosterId
      ) as SubscriptionBoosterInput
    },
    resetCurrentSelectedSubscriptionBoosters() {
      this.selectedSubscriptionBoosters = []
      useBooking().subscriptionBoosters = []
    },
  },
})
