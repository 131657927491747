<template>
  <Modal
    v-if="state.showModal"
    :show="state.showModal"
    type="msg"
    position="top"
    @close="state.showModal = false"
  >
    <template #body>
      <h2 v-if="state.message.heading != ''" class="h2">
        {{ state.message.heading }}
      </h2>
      <p>{{ state.message.text }}</p>
      <div v-if="state.message.phone != '' && state.message.phone != undefined" class="iconized">
        <Icon name="phone" /> <span class="h2-size"> {{ state.message.phone }} </span>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { EventBus } from '@/event-bus'
import { onUnmounted, reactive, defineAsyncComponent } from 'vue'
import phone from '@/assets/svg/phone.svg'
import { CommonMessage } from '@/models/Application/CommonMessage'
import Icon from '@/components/generic/Icon.vue'

const Modal = defineAsyncComponent(() => import('@/components/generic/Modal.vue'))

const state = reactive({
  message: {} as CommonMessage,
  showModal: false,
})

function phoneSrc(): typeof phone {
  return phone
}

function onCommonMessage(msg: CommonMessage | undefined): void {
  state.showModal = true
  if (msg !== undefined) {
    state.message = msg
  }
}

EventBus.on('common-message', (msg: CommonMessage | undefined) => {
  state.showModal = true
  if (msg !== undefined) {
    state.message = msg
  }
  onCommonMessage(msg)
})

onUnmounted(() => {
  EventBus.off('common-message', (msg: CommonMessage | undefined) => {
    onCommonMessage(msg)
  })
})
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';
p {
  margin: 0;
  &:first-child {
    padding-right: var(--size-double);
  }
}
.iconized {
  margin-top: var(--size-half);
}
.icon {
  background: var(--color-bilia-primary-blue);
  padding: var(--size-half);
  border-radius: 50%;
}
</style>
