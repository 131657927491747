<template>
  <div class="vehicle-info">
    <template v-if="vehicle && !objectIsEmpty(vehicle)">
      <span v-if="viewRegnumber" class="regnummer">{{ getRegistrationNumberPresentation() }}</span>
      <span v-if="vehicle.make">{{ formatBrandNames(vehicle.make) }}</span>
      <span v-if="vehicle.model">{{ vehicle.model }}</span>
      <span v-if="vehicle.year !== 0">{{ vehicle.year }}</span>
    </template>
    <template v-else-if="!objectIsEmpty(bookingStore.vehicle)">
      <span v-if="viewRegnumber" class="regnummer">{{ getRegistrationNumberPresentation() }}</span>
      <span v-if="bookingStore.vehicle.make">{{
        formatBrandNames(bookingStore.vehicle.make)
      }}</span>
      <span v-if="bookingStore.vehicle.model">{{ bookingStore.vehicle.model }}</span>
      <span v-if="bookingStore.vehicle.year">{{ bookingStore.vehicle.year }}</span>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { PropType, reactive, watch } from 'vue'
import formatBrandNames from '@/mixins/sharedFunctions/formatBrandNames'
import registrationNumberPresentation from '@/mixins/sharedFunctions/registrationNumberPresentation'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { useBooking } from '@/store/booking.store'
import textResource from '@/mixins/sharedFunctions/textResource'

const props = defineProps({
  vehicle: {
    type: Object as PropType<Vehicle>,
    required: false,
    default: {} as Vehicle,
  },
  viewRegnumber: {
    type: Boolean,
    required: false,
    default: false as boolean,
  },
  showError: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const bookingStore = useBooking()

const state = reactive({
  vehicle: {} as Vehicle,
})

function getRegistrationNumberPresentation(): string {
  return registrationNumberPresentation(state.vehicle.registrationNumber)
}

watch(
  () => props.vehicle,
  (newVehicle) => {
    state.vehicle = { ...newVehicle } as Vehicle
  }
)

state.vehicle = props.vehicle
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.regnummer {
  text-transform: uppercase;
}

.vehicle-info span:not(:last-child):after {
  content: ', ';
}

.warning {
  //todo : add error color
  @include caption;
  color: var(--color-brand-red);
}
</style>
