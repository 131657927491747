export class VehicleRequest {
  RegistrationNumber: string
  Culture: string
  Mileage: number
  CarMake: string
  Model: string
  siteId: string

  constructor(
    registrationNumber: string,
    culture: string,
    mileage: number,
    carMake: string,
    model: string,
    siteId: string
  ) {
    this.RegistrationNumber = registrationNumber
    this.Culture = culture
    this.Mileage = mileage
    this.CarMake = carMake
    this.Model = model
    this.siteId = siteId
  }
}
