import { submitBookingRequest } from '@/api/booking.service'
import { getDriverByNameRequest } from '@/api/driver.service'
import { getGoogleMapImageRequest } from '@/api/location.service'
import { CustomerInformation } from '@/models/Book/customerInformation'
import { Options } from '@/models/Book/options'
import { ServicePlan } from '@/models/Vehicle/ServicePlan'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { defineStore } from 'pinia'
import { usePreliminaryBooking } from './preliminary-booking.store'
import { useSettings } from './settings.store'
import { EventBus } from '@/event-bus'
import { salesWheel } from '@/models/Vehicle/salesWheel'
import { Branch } from '@/models/Branch/Branch'
import Container from '@/models/Book/Container'
import { useCalendar } from './calendar.store'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { useRouter } from '@/router'
import useTracking from '@/mixins/tracking/useTracking'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import { useConfigCat } from './config-cat.store'
import deformatPhoneNumber from '@/mixins/sharedFunctions/deformatPhoneNumber'
import {
  MoreSaleInput,
  CustomerInformationInput,
  SubmitBookingQueryInput,
  WorkshopService,
  Day,
  BookmakerTechnicianType,
  GetScheduleResponse,
  Customer,
  SubscriptionBooster,
  SubscriptionBoosterData,
} from '@/types/generated-types'
import ConfigInput, { MoreSaleExtended } from '@/types/extendedTypes/configInput'
import { operationTypeEnum } from '@/types/extendedTypes/operationType'
import { useBranches } from './branch.store'
import { currentSite } from '@/api/http.client'
import { useMoreSale } from './moresale.store'
import { SubscriptionBoosterInput, useSubscriptionBooster } from './subscription-booster.store'

interface BookingState {
  vehicle: Vehicle
  customerInformation: CustomerInformation
  options: Options
  icsCalendar: string
  googleImage: string
  selectedMoreSales: MoreSaleExtended[]
  servicePlan: ServicePlan
  manageBookingUrl: string
  bookingId: string
  responseCode: number
  responseMessage: string
  correlationId: string | (string | null)[] | null
  campaignCustomer: Customer
  selectedBranch: Branch | undefined
  selectedContainer: Container | undefined
  SelectedDate: Day
  bookedWheels: { tire: salesWheel; amount: number }
  submitBookingQueryInput: SubmitBookingQueryInput
  bookingRequestHasPossiblyError: boolean
  workshopService: WorkshopService
  serviceConfigurationInputsResponse: ConfigInput[]
  subscriptionBoosters: SubscriptionBooster[]
}

export const useBooking = defineStore('booking', {
  state: () =>
    ({
      vehicle: {} as Vehicle,
      customerInformation: {} as CustomerInformation,
      options: {} as Options,
      icsCalendar: '' as string,
      googleImage: '' as string,
      selectedMoreSales: [] as MoreSaleExtended[],
      servicePlan: {} as ServicePlan,
      manageBookingUrl: '' as string,
      bookingId: '' as string,
      responseCode: 0 as number,
      responseMessage: '' as string,
      correlationId: '' as string,
      campaignCustomer: {} as Customer,
      bookedWheels: {},
      selectedBranch: {} as Branch | undefined,
      selectedContainer: {} as Container,
      SelectedDate: {} as Day,
      bookingRequestHasPossiblyError: false as boolean,
      workshopService: {} as WorkshopService,
      serviceConfigurationInputsResponse: [] as ConfigInput[],
      subscriptionBoosters: [] as SubscriptionBoosterInput[],
    } as BookingState),
  actions: {
    async submitBooking(): Promise<void> {
      const { mouseflowFormSubmitFailure, mouseflowFormSubmitAttempt } = useTracking()

      mouseflowFormSubmitAttempt('customer-form')

      // Validate customer input
      this.validateCustomerInformation()

      // Create initial booking query object
      this.createSubmitBookingQueryInput()

      // Conditionally add optional query params to submit booking query object
      this.addMoreSalesToBooking()
      this.addSubscriptionBoostersToBooking()
      this.addPriceToBooking()
      this.addTechnicanToBooking()
      this.addMileageToBooking()
      this.addBookedWheelsToBooking()
      this.addServicePlanToBooking()
      this.addTireHotelToBooking()

      useConfigCat()
        .getFeatureFlag('killflagbokalogbookingrequesthaspossiblyerror', true)
        .then((status: boolean) => {
          if (status && this.bookingRequestHasPossiblyError) {
            userMonitoring().trackError(
              `submitBooking, bookingRequest has possible error: ${JSON.stringify(
                this.submitBookingQueryInput
              )}`,
              'submitBooking, bookingRequest has possible error',
              { bookingQueryInput: this.submitBookingQueryInput }
            )
          }
        })

      /* BOOK SERVICE */
      if (this.selectedContainer == undefined || this.selectedContainer?.containerId == 0) {
        userMonitoring().trackError(
          `${ErrorTypes.faultyData}: submitBooking, containerId failed: ${JSON.stringify(
            this.submitBookingQueryInput
          )}`,
          'submitBooking, containerId failed',
          { bookingQueryInput: this.submitBookingQueryInput }
        )
      }

      await submitBookingRequest(this.submitBookingQueryInput)
        .then((bookingResponse) => {
          switch (bookingResponse.__typename) {
            case 'StatusCodeResponse':
              if (bookingResponse.statusCode == 200) {
                this.onBookingSubmitted()
              } else {
                mouseflowFormSubmitFailure('customer-form')
                EventBus.emit('booking-is-failed')
                userMonitoring().trackAction('CONTACT - Booking Failed')
                userMonitoring().trackError(
                  `submitBooking failed: ${
                    bookingResponse.statusCode
                  }, bookingQueryInput: ${JSON.stringify(this.submitBookingQueryInput)}`,
                  'submitBooking failed',
                  { bookingQueryInput: this.submitBookingQueryInput }
                )
              }
              break
            case 'SubmittedBooking':
              this.manageBookingUrl = bookingResponse.manageBookingUrl ?? ''
              this.bookingId = bookingResponse.bookingId ?? ''
              this.onBookingSubmitted()
              break
            default:
              break
          }
        })
        .catch((error: Error) => {
          EventBus.emit('booking-is-failed')
          userMonitoring().trackAction('CONTACT - Booking Failed')
          userMonitoring().trackError(
            `submitBooking failed: ${error}, bookingQueryInput: ${JSON.stringify(
              this.submitBookingQueryInput
            )}`,
            'submitBooking failed',
            { bookingQueryInput: this.submitBookingQueryInput }
          )
        })
    },
    onBookingSubmitted(): void {
      const { trackService, trackWheels, mouseflowFormSubmitSuccess } = useTracking()
      const preliminaryBookingStore = usePreliminaryBooking()

      mouseflowFormSubmitSuccess('customer-form')
      userMonitoring().trackAction('CONTACT - Booking succeed')
      trackService(
        operationTypeEnum[this.workshopService.operationType],
        this.servicePlan?.price?.price,
        this.servicePlan?.price?.message,
        'customer_form',
        'booking',
        this.workshopService.externalUrl,
        this.workshopService.eventName,
        this.selectedMoreSales
      )
      trackWheels(this.bookedWheels?.tire, this.bookedWheels.amount)
      EventBus.emit('booking-is-created')
      preliminaryBookingStore.isBooked = true
      useRouter().push({ name: 'confirmation' })
    },
    validateCustomerInformation(): void {
      /* SAFETY CHECK FOR CUSTOMERINFO */
      if (
        this.customerInformation.firstName == null ||
        this.customerInformation.lastName == null ||
        this.customerInformation.email == null ||
        this.customerInformation.phone == null
      ) {
        userMonitoring().trackError(
          `${
            ErrorTypes.feState
          }: Customer info was null when the booking was initiated - Booking: ${JSON.stringify(
            this
          )}`
        )
      }
      if (
        this.customerInformation.firstName == undefined ||
        this.customerInformation.lastName == undefined ||
        this.customerInformation.email == undefined ||
        this.customerInformation.phone == undefined
      ) {
        userMonitoring().trackError(
          `${
            ErrorTypes.feState
          }: Customer info was undefined when the booking was initiated - Booking: ${JSON.stringify(
            this
          )}`
        )
      }
      if (
        this.customerInformation.firstName == '' ||
        this.customerInformation.lastName == '' ||
        this.customerInformation.email == '' ||
        this.customerInformation.phone == ''
      ) {
        userMonitoring().trackError(
          `${
            ErrorTypes.feState
          }: Customer info was ''(empty string) when the booking was initiated - Booking: ${JSON.stringify(
            this
          )}`
        )
      }
    },

    createSubmitBookingQueryInput(): void {
      const preliminaryBookingStore = usePreliminaryBooking()
      const settingsStore = useSettings()
      /* CREATE BOOKING OBJECT */
      this.submitBookingQueryInput = {
        branchCode: String(this.validateValue(this.selectedBranch?.branchCode)) ?? '',
        branchName: String(this.validateValue(this.selectedBranch?.name)) ?? '',
        branchPhone: String(this.validateValue(this.selectedBranch?.phone)) ?? '',
        branchPosition: {
          latitude: Number(this.validateValue(this.selectedBranch?.latitude)) ?? 0,
          longitude: Number(this.validateValue(this.selectedBranch?.longitude)) ?? 0,
        },
        branchPostalNumber: String(this.validateValue(this.selectedBranch?.postalNumber)) ?? '',
        branchStreetAddress: String(this.validateValue(this.selectedBranch?.streetAddress)) ?? '',
        branchTown: String(this.validateValue(this.selectedBranch?.town)) ?? '',
        carMake: String(this.validateValue(this.vehicle.make)) ?? '',
        carMakeYear: Number(this.validateValue(this.vehicle.year)) ?? 0,
        carModel: String(this.validateValue(this.vehicle.model)) ?? '',
        container: {
          containerId: this.validateValue(this.selectedContainer?.containerId) ?? 0,
        },
        culture: this.validateValue(settingsStore.culture) ?? '',
        customerInformation:
          this.validateValue(this.customerInformation) ?? ({} as CustomerInformationInput),
        options: {
          branchId: this.validateValue(this.selectedBranch?.id),
          serviceId: this.validateValue(this.workshopService.id),
          leaveCarTimeText: `${this.options.leaveCarTime?.label} ${this.options.leaveCarTime?.text}`,
          customerWait: this.validateValue(this.options.customerWait) ?? false,
          smsReminder: this.validateValue(this.options.smsReminder) ?? false,
          customerComment: this.validateValue(this.options.customerComment) ?? '',
          hasServiceContractInterest:
            this.validateValue(this.options.hasServiceContractInterest) ?? false,
        },
        preliminaryBookingId:
          this.validateValue(preliminaryBookingStore.preliminaryBooking.PreliminaryBookingId) ?? '',
        regionCode: this.validateValue(this.selectedBranch?.regionCode) ?? 0,
        registrationNumber: this.validateValue(this.vehicle.registrationNumber),
        selectedDate: this.validateValue(this.SelectedDate.date),
        siteId: this.validateValue(settingsStore.siteData.siteId) ?? '',
        ...(this.vehicle.hasTireHotel
          ? { hasTireHotel: this.validateValue(this.vehicle.hasTireHotel) }
          : {}),
        typedMileage: this.validateValue(this.vehicle.typedMileage),
        wheels: {
          uuid: this.validateValue(this.vehicle.uuid) ?? '',
          amount: this.validateValue(this.bookedWheels?.amount) ?? 0,
          wheelId: this.validateValue(this.bookedWheels.tire?.id) ?? '',
          price: this.validateValue(this.bookedWheels.tire?.currentPrice) ?? 0,
        },
        indexPrefix: currentSite?.indexPrefix,
      }
    },

    addPriceToBooking(): void {
      if (!objectIsEmpty(this.workshopService.price) && this.workshopService.price) {
        this.submitBookingQueryInput.price = {
          price: this.workshopService.price.price,
          isFromPrice: this.workshopService.price.isFromPrice,
          packageCode: this.workshopService.price.packageCode,
          biliaCardPrice: this.workshopService.price.biliaCardPrice,
          priceDescription: this.workshopService.price.priceDescription,
          disclaimer: this.workshopService.price.disclaimer,
          textPrice: this.workshopService.price.textPrice,
          priceGroupName: this.workshopService.price.priceGroupName,
        }
      }
    },

    addTireHotelToBooking(): void {
      if (this.vehicle.hasTireHotel) {
        this.submitBookingQueryInput.hasTireHotel = this.validateValue(this.vehicle.hasTireHotel)
      }
    },

    addTechnicanToBooking(): void {
      if (
        this.selectedContainer?.technician !== undefined &&
        this.submitBookingQueryInput.container
      ) {
        this.submitBookingQueryInput.container.technician = {
          email: this.selectedContainer.technician.email,
          title: this.selectedContainer.technician.title ?? '',
          photoUrl: this.selectedContainer.technician.photoUrl,
          id: this.selectedContainer.technician.id,
          name: this.selectedContainer.technician.name,
          phone: this.selectedContainer.technician.phone,
          regionCode: this.selectedBranch?.regionCode ?? 0,
        }
      }
    },

    addMoreSalesToBooking(): void {
      const moreSaleStore = useMoreSale()
      moreSaleStore.setSelectedMoreSalesToCurrentSelected()
      if (this.selectedMoreSales.length > 0) {
        const MoreSales: MoreSaleInput[] = []
        this.selectedMoreSales.forEach((moreSale) => {
          MoreSales.push({
            id: moreSale.id,
            name: moreSale.name,
            description: moreSale.description,
            eventName: moreSale.eventName,
            requireSocialSecurityNumber: moreSale.requireSocialSecurityNumber,
            socialSecurityNumber: moreSale.socialSecurityNumber,
            getArticleAndPriceFromEcommerce: moreSale.getArticleAndPriceFromEcommerce,
            showCommentField:
              moreSale.showCommentField ||
              moreSaleStore.hasCustomerCommentServiceConfigurationInputs(moreSale), // Forcing showCommentField to true if we have customer comment inputs. This due to limitation in Backend.
            userComment: `${moreSale.userComment ?? ''} ${this.concatenateUserComments(
              moreSale.serviceConfigurationInputs as ConfigInput[]
            )} `,
            price: {
              price: moreSale.price?.price ?? 0,
              biliaCardPrice: moreSale.price?.biliaCardPrice,
              textPrice: moreSale.price?.textPrice ?? '',
              priceDescription: moreSale.price?.priceDescription ?? '',
              isFromPrice: moreSale.price?.isFromPrice ?? false,
              disclaimer: moreSale.price?.disclaimer ?? '',
              packageCode: moreSale.price?.packageCode,
            },
          })
        })
        this.submitBookingQueryInput.moreSales = MoreSales
      }
    },

    addSubscriptionBoostersToBooking(): void {
      const subscriptionBoosterStore = useSubscriptionBooster()
      this.subscriptionBoosters = [...subscriptionBoosterStore.selectedSubscriptionBoosters]
      if (subscriptionBoosterStore.selectedSubscriptionBoosters.length > 0) {
        const subscriptionBoosterData: SubscriptionBoosterData[] = []
        subscriptionBoosterStore.selectedSubscriptionBoosters.forEach(
          (subscriptionBoosterInput) => {
            subscriptionBoosterData.push(
              subscriptionBoosterStore.toSubscriptionBoosterData(subscriptionBoosterInput)
            )
          }
        )
        this.submitBookingQueryInput.options.subscriptionBoosterData = subscriptionBoosterData
      }
    },

    addMileageToBooking(): void {
      if (this.vehicle.typedMileage > 0) {
        this.submitBookingQueryInput.typedMileage = this.validateValue(this.vehicle.typedMileage)
      }
    },

    addBookedWheelsToBooking(): void {
      if (!objectIsEmpty(this.bookedWheels)) {
        this.submitBookingQueryInput.wheels = {
          uuid: this.validateValue(this.vehicle.uuid) ?? '',
          amount: this.validateValue(this.bookedWheels?.amount) ?? 0,
          wheelId: this.validateValue(this.bookedWheels.tire?.id) ?? '',
          price: this.validateValue(this.bookedWheels.tire?.currentPrice) ?? 0,
        }
      }
    },

    addServicePlanToBooking(): void {
      if (!objectIsEmpty(this.servicePlan)) {
        this.submitBookingQueryInput.servicePlan = {
          price: {
            message: this.servicePlan.price.message ?? '',
            price: this.servicePlan.price.price,
            reducedPrice: this.servicePlan.price.reducedPrice,
          },
          hasServiceAgreement: this.servicePlan.hasServiceAgreement,
          message: '',
          serviceActivities: this.servicePlan.serviceActivites,
          servicePlanSummarize: this.servicePlan.servicePlanSummarize,
        }
      }
    },

    async fetchDriverByName(uuid: string): Promise<void> {
      const settingStore = useSettings()
      const payload = {
        mobileNumber: this.customerInformation.phone
          ? deformatPhoneNumber(this.customerInformation.phone)
          : '',
        uuid: uuid ? uuid : '',
        culture: settingStore.culture,
        registrationNumber: this.vehicle.registrationNumber ? this.vehicle.registrationNumber : '',
      }
      return await getDriverByNameRequest(payload).then((driver) => {
        this.campaignCustomer = {
          ...driver,
          registrationNumber: driver?.registrationNumber
            ? driver.registrationNumber
            : this.vehicle.registrationNumber,
        }
      })
    },

    async fetchGoogleMapImage(): Promise<void> {
      if (this.selectedBranch) {
        await getGoogleMapImageRequest(
          this.selectedBranch.latitude,
          this.selectedBranch.longitude,
          this.selectedBranch.name
        ).then((googleMap) => {
          this.googleImage = googleMap
        })
      }
    },

    clearSelectedBranch(): void {
      const calendarStore = useCalendar()
      this.selectedBranch = undefined
      calendarStore.branchSchedule = {} as GetScheduleResponse
      calendarStore.currentSchedule = [] as Day[]
      calendarStore.technicians = [] as BookmakerTechnicianType[]
    },

    clearSelectedTechnician(): void {
      useBranches().personalServiceTechnicianId = undefined
      this.selectedContainer = undefined
    },

    hasTireInCart(): boolean {
      return !objectIsEmpty(this.bookedWheels)
    },

    getFirstBookableDate(oneDaySelected: boolean): string {
      const startDate = this.hasTireInCart()
        ? this.bookedWheels?.tire?.firstBookableDateForTire
        : oneDaySelected
        ? this.SelectedDate.date
        : new Date().toISOString()

      return startDate
    },

    clearBookedWheels() {
      this.bookedWheels = {} as { tire: salesWheel; amount: number }
    },

    resetBooking() {
      this.$reset()
    },

    validateValue(value: any) {
      if (value === null || value === undefined) {
        this.bookingRequestHasPossiblyError = true
      }
      return value as typeof value
    },

    concatenateUserComments(configInputs: ConfigInput[]): string {
      let userComments = ''
      if (!configInputs || (configInputs && configInputs.length == 0)) {
        return ''
      } else {
        const userCommentsInputs = configInputs.filter(
          (input) => input.serviceConfigurationInputType == 'CUSTOMER_COMMENT'
        ) as ConfigInput[]
        userCommentsInputs.forEach((input) => {
          // Just add the response for now. If we have multiple fields we will also need to add the heading.
          // userComments += input.header
          // userComments += ':\n'
          userComments += input.response ?? ''
          // userComments += '\n\n'
        })
      }
      return userComments
    },

    SetCustomerCommentToPreivewCollectedRequests() {
      this.customerInformation.customerComment =
        this.customerInformation.customerComment ||
        this.concatenateUserComments(this.serviceConfigurationInputsResponse)
    },
  },
})
function mouseflowFormSubmitSuccess(arg0: string) {
  throw new Error('Function not implemented.')
}
