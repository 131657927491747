import { GetBranchesResponse } from '@/models/Branch/getBranchesResponse'
import { BranchName } from '@/models/Branch/BranchName'
import { Branch } from '@/models/Branch/Branch'
import { GRAPHQL, HTTP, currentSite } from './http.client'
import { useSettings } from '@/store/settings.store'
import scheduleQuery from '@/api/queries/ScheduleQuery.graphql'
import timeSlotQuery from '@/api/queries/TimeSlotQuery.graphql'
import branchNamesQuery from '@/api/queries/BranchNamesQuery.graphql'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import { ApolloError } from '@apollo/client'
import { BranchNamesType, GetScheduleResponse, Slot } from '@/types/generated-types'

export type BranchesQueryParams = {
  bookingType: string | number
  isSlot: boolean
  regNr: string
  carMake: string
  vehicleCode: string
  isTireHotel: boolean
  hasTireInCart: boolean
  isTransport: boolean
  serviceId: string
}

const getBranchesQueryParams = (branchQueryParams: BranchesQueryParams) => {
  return {
    BookingType: branchQueryParams.bookingType,
    IsSlot: branchQueryParams.isSlot,
    RegistrationNumber: branchQueryParams.regNr,
    CarMake: branchQueryParams.carMake,
    CarMakeCode: branchQueryParams.vehicleCode,
    IsTireHotel: branchQueryParams.isTireHotel,
    HasTireInCart: branchQueryParams.hasTireInCart,
    IsTransport: branchQueryParams.isTransport,
    ServiceId: branchQueryParams.serviceId,
  }
}

export const getBranchesByRegionId = (
  regionId: string,
  branchQueryParams: BranchesQueryParams
): Promise<GetBranchesResponse> => {
  const settingStore = useSettings()
  const endpoint = 'Branch/GetBranchesByRegionId'
  const payload = {
    SiteId: settingStore.siteData.siteId,
    Culture: settingStore.siteData.culture,
    RegionId: regionId,
    IndexPrefix: currentSite?.indexPrefix,
    ...getBranchesQueryParams(branchQueryParams),
  }
  return HTTP.post<any>(endpoint, payload)
    .then((response) => {
      if (response.status == 204) {
        return { branches: [] as Branch[] } as GetBranchesResponse
      }
      return response.data
    })
    .catch((error) => {
      userMonitoring().trackErrorApi(endpoint, payload, error)
      return { branches: [] as Branch[] } as GetBranchesResponse
    })
}

interface IBranchNamesData {
  branchNames: BranchNamesType[]
}

export const getBranchNames = (
  bookingType: number,
  carMakeCode: string,
  isTransport: boolean
): Promise<BranchNamesType[]> => {
  const variables = {
    bookingType: bookingType,
    carMakeCode: carMakeCode,
    indexPrefix: currentSite?.indexPrefix,
    isTransport: isTransport,
  }
  return GRAPHQL.query<IBranchNamesData>({
    query: branchNamesQuery,
    variables: {
      branchNamesQuery: variables,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('BranchNames', JSON.stringify(response.errors))
      }
      return response.data.branchNames
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('BranchNames', error, JSON.stringify(variables))
      return [] as BranchNamesType[]
    })
}

export const getBranchesByRegistrationNumber = (
  pstService: boolean,
  branchId: string,
  branchQueryParams: BranchesQueryParams
): Promise<GetBranchesResponse> => {
  const settingStore = useSettings()
  const endpoint = 'Branch/GetBranchesByRegistrationNumber'
  const payload = {
    SiteId: settingStore.siteData.siteId,
    Culture: settingStore.siteData.culture,
    PstService: pstService,
    BranchId: branchId,
    IndexPrefix: currentSite?.indexPrefix,
    ...getBranchesQueryParams(branchQueryParams),
  }
  return HTTP.post<any>(endpoint, payload)
    .then((response) => {
      if (response.status == 204) {
        return { branches: [] as Branch[] } as GetBranchesResponse
      }
      return response.data.response
    })
    .catch((error) => {
      userMonitoring().trackErrorApi(endpoint, payload, error)
      return { branches: [] as Branch[] } as GetBranchesResponse
    })
}

export const getBranchesByPosition = (
  longitude: number,
  latitude: number,
  branchQueryParams: BranchesQueryParams
): Promise<GetBranchesResponse> => {
  const settingStore = useSettings()
  const endpoint = 'Branch/GetBranchesByPosition'
  const payload = {
    SiteId: settingStore.siteData.siteId,
    Culture: settingStore.siteData.culture,
    IndexPrefix: currentSite?.indexPrefix,
    Longitude: longitude,
    Latitude: latitude,
    ...getBranchesQueryParams(branchQueryParams),
  }
  return HTTP.post<any>(endpoint, payload)
    .then((response) => {
      if (response.status == 200) {
        return response.data
      } else if (response.status == 204) {
        return { branches: [] as Branch[] } as GetBranchesResponse
      } else {
        userMonitoring().trackErrorApi(endpoint, payload, { status: response.status })
        return { branches: [] as Branch[] } as GetBranchesResponse
      }
    })
    .catch((error) => {
      userMonitoring().trackErrorApi(endpoint, payload, error)
      return { branches: [] as Branch[] } as GetBranchesResponse
    })
}

export const getBranchesByBranchId = (
  branchId: string,
  branchQueryParams: BranchesQueryParams
): Promise<GetBranchesResponse> => {
  const settingStore = useSettings()
  const endpoint = 'Branch/GetBranchesByBranchId'
  const payload = {
    SiteId: settingStore.siteData.siteId,
    Culture: settingStore.siteData.culture,
    BranchId: branchId,
    IndexPrefix: currentSite?.indexPrefix,
    ...getBranchesQueryParams(branchQueryParams),
  }
  return HTTP.post<any>(endpoint, payload)
    .then((response) => {
      if (response.status == 204) {
        return { branches: [] as Branch[] } as GetBranchesResponse
      }
      return response.data
    })
    .catch((error) => {
      userMonitoring().trackErrorApi(endpoint, payload, error)
      return { branches: [] as Branch[] } as GetBranchesResponse
    })
}

export const getBranchByIdRequest = (
  siteId: string,
  bookingType: string | number,
  regNr: string,
  vehicleCode: string,
  carMake: string,
  culture: string,
  id: string,
  isTireHotel: boolean,
  serviceId: string
): Promise<GetBranchesResponse> => {
  return HTTP.post<GetBranchesResponse>(`Branch/GetTireHotelBranches`, {
    SiteId: siteId,
    BookingType: bookingType,
    RegistrationNumber: regNr,
    VehicleCode: vehicleCode,
    CarMake: carMake,
    culture: culture,
    BranchId: id,
    isTireHotel: isTireHotel,
    IndexPrefix: currentSite?.indexPrefix,
    serviceId: serviceId,
  })
    .then((response) => response.data)
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.api}: Branch/BranchById failed: ${JSON.stringify(error)} `
      )
      return {} as GetBranchesResponse
    })
}

interface IScheduleQueryData {
  schedule: GetScheduleResponse
}

export const getSchedule = (
  branchId: string,
  carMakeCode: string,
  hasTireInCart: boolean,
  isTireHotel: boolean,
  numberOfDays: number,
  oneDaySelected: boolean,
  serviceId: string,
  startDate: string,
  forwardDays = false
): Promise<GetScheduleResponse> => {
  const settingStore = useSettings()
  const requestVariables = {
    branchId: branchId,
    carMakeCode: carMakeCode,
    culture: settingStore.culture,
    hasTireInCart: hasTireInCart,
    isTireHotel: isTireHotel,
    oneDaySelected: oneDaySelected,
    serviceId: serviceId,
    startDate: startDate,
    numberOfDays: numberOfDays,
    indexPrefix: currentSite?.indexPrefix,
    siteId: settingStore.siteData.siteId,
    forwardDays: forwardDays,
  }
  return GRAPHQL.query<IScheduleQueryData>({
    query: scheduleQuery,
    variables: requestVariables,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('ScheduleQuery', JSON.stringify(response.errors))
      }
      return response.data.schedule
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('ScheduleQuery', error, JSON.stringify(requestVariables))
      return {} as GetScheduleResponse
    })
}

interface ITimeSlotQueryData {
  timeSlots: Slot[]
}

export const getTimeSlots = (branchId: string, selectedDate: string): Promise<Slot[]> => {
  return GRAPHQL.query<ITimeSlotQueryData>({
    query: timeSlotQuery,
    variables: {
      timeSlotsQuery: {
        branchId: branchId,
        indexPrefix: currentSite?.indexPrefix,
        selectedDate: selectedDate,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('TimeSlotQuery', JSON.stringify(response.errors))
      }
      return response.data.timeSlots
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql(
        'TimeSlotQuery',
        error,
        JSON.stringify({ branchId: branchId, selectedDate: selectedDate })
      )
      return [] as Slot[]
    })
}
