import { CarMake } from '@/models/Vehicle/carMake'
import { HTTP } from './http.client'
import { useSettings } from '@/store/settings.store'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'

export const getCarMakeNamesRequest = (culture: string): Promise<CarMake[]> => {
  return HTTP.get<CarMake[]>(`CarMakes/${useSettings().siteData.siteId}`)
    .then((response) => response.data)
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.api}: CarMakes/${useSettings().siteData.siteId} failed with error: ${error}`
      )
      return [] as CarMake[]
    })
}
