export enum SystemTextKeys {
  carMake = 'carMake',
  vehicle = 'vehicle',
  timeAndPlace = 'timeAndPlace',
  customer = 'customer',
  bookConfirmationText = 'bookConfirmationText',
  desktop = 'desktop',
  general = 'general',
  externalMake = 'externalMake',
  rimRenovation = 'rimRenovation',
}
